<template>
  <div>
    <section class="py-5 gifting">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-lg-offset-3">
            <div class="row">
              <div class="col-lg-12">
                <div class="card shadow">
                  <div class="card-body py-5">
                    <h5 class="card-title text-center">
                      <div class="pink">
                        <span class="icon check size-21 align-middle"></span>
                        Transaction Successful
                      </div>

                      <div class="orange fw-bold mt-4">
                        {{ getSKU.items ? getSKU.items[0].skuName : "" }}
                      </div>

                      <div class="font-8 mt-2">
                        was sucessfully sent to
                        <!-- {{ getUser.mobileNumber }} -->
                        {{ maskString(getUser.mobileNumber) }}
                      </div>
                    </h5>
                    <div class="breakdown-border mt-5">
                      <div class="row">
                        <div class="col-sm-7">Amount</div>
                        <div class="col-sm-5">
                          <div class="float-end">
                            <!-- {{ getSKU.items[0].price }} -->
                            {{ getSKU.items ? getSKU.items[0].price : 0 }}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-7">Convenience Fee</div>
                        <div class="col-sm-5">
                          <div class="float-end">0.00</div>
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-sm-7">Paid amount</div>
                        <div class="col-sm-5">
                          <div class="float-end">
                            {{ getSKU.items[0].price }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="">
                      <div class="col-lg-12 text-center mt-5">
                        <router-link to="/topup" class="btn btn-dblue px-5">
                          Topup Again
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <button @click="topFunction" id="myBtn" title="Go to top">Top</button>
  </div>
</template>

<script>
// import SkuItem from "../components/SkuItem.vue";
export default {
  name: "Gifting",
  components: {
    // SkuItem,
  },
  data() {
    return {
      skus: [],
    };
  },
  methods: {
    async getSKUs() {
      await this.$api2
        .get("/fiber-sku?group=true")
        .then((response) => {
          const res = response.data;
          if (res.status) {
            this.skus = res.data.fiberSku;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },

    proceedToCheckout() {
      // const a = this.$store.getters.GET_SKU;
      // console.log(a);

      this.$router.push({ path: "/checkout" });
    },

    maskString(str) {
      return (
        str
          .replace(/\s+/g, "")
          // .replace("+", "")
          .replace(/^(\+?[\d]{3})\d+(\d{4})$/g, "$1*****$2")
      );
    },
  },

  created() {
    // this.getSKUs();
  },

  computed: {
    getSKU() {
      return this.$store.getters.GET_SKU;
    },

    getUser() {
      return this.$store.getters.GET_USER;
    },
  },
};
</script>
